import http from "@/services/http";

const users = (params) => { return http.get("users", { params: params }) }
const getById = (id) => http.get(`users/${id}`)
const store = (user) => http.post('users', user)
const update = (id, user) => http.put(`users/${id}`, user)
const destroy = (userId) => http.delete(`user/${userId}`)

export default {
  users,
  getById,
  store,
  update,
  destroy
}
