export default {
   I_WON_A_CONTRACT_OR_BID : 1,
   RECEIVE_AN_ADVANCE_PAYMENT : 2,
   PARTICIPATE_IN_A_BID_OR_COMPETITION : 3,
   I_HAVE_A_LAWSUIT : 4,
   WORKS_AND_OR_RENOVATIONS : 11,
   SERVICES_PROVISION : 12,
   SUPPLY_OF_GOODS_AND_OR_MATERIALS : 13,
   MAKE_A_LABOR_APPEAL_DEPOSIT : 41,
   ENSURE_A_LABOR_EXECUTION : 42,
   I_HAVE_TAX_CREDIT_OR_INSTALLMENT : 43,
   TAX_ENFORCEMENT : 44,
   APPEAL_A_CIVIL_ACTION : 45,
   REPLACEMENT_OF_APPEAL_DEPOSIT: 46,
   HARVEST : 100,
   EQUIPMENT : 101,
   GENERAL_RC : 110,
   DEO : 111,
   EEO : 112,
   ENVIRONMENTAL_RISKS : 113,
   RC_EVENTS : 114,
   RC_PROVISION_OF_SERVICES : 115,
   PAYMENT_RETENTION : 123,
   CUSTOMS : 124,
   REAL_ESTATE : 125,
   COMPLETION_BOND : 126,
   CORRECTIVE_MAINTENANCE : 127,
   OTHERS_GUARANTEE : 131,
   PHYSICAL_PERSON : 132,
   LEGAL_PERSON : 133,
   BUILDINGS : 134,
   APARTMENT : 135,
   HOUSES : 136,
   COMMERCIAL_SETS : 137,
   CIVIL_WORKS : 138,
   BRIDGES_VIADUCTS : 139,
   TUNNELS : 140,
   PAVING : 141,
   EQUIPMENT_INSTALLATIONS_AND_ASSEMBLIES : 142,
   RENOVATIONS : 143,
   AERONAUTICAL : 144,
   FLEET : 145,
   INDIVIDUAL_AUTO : 146,
   BUSINESS_PROPERTY_INSURANCE : 147,
   RD_EQUIPMENT : 148,
   SHIPBUILDER : 149,
   CYBERNETIC : 150,
   PORT_OPERATOR : 151,
   NAUTICAL : 152,
   INDIVIDUAL_LIFE_INSURANCE : 153,
   GROUP : 154,
   SINGLE : 155,
   MONTHLY : 156,
   HEALTH : 157,
   DENTAL : 158,
   OTHERS : 159,
   RESIDENTIAL : 160,
   CYBERNETIC_CIVIL : 161,
   CUSTOMS_TRANSIT : 162,
   CUSTOMS_TEMPORARY_ADMISSION : 163,
   ENERGY_PAYMENT : 164,
}
